const {
  REACT_APP_STRIPE_CLIENT_API_KEY="pk_test_TYooMQauvdEDq54NiTphI7jx",
  REACT_APP_STRIPE_CLIENT_SECRET="",
  REACT_APP_FIREBASE_EMULATOR_DATABASE_HOST="localhost",
  REACT_APP_FIREBASE_EMULATOR_DATABASE_PORT="9000",
  REACT_APP_FIREBASE_EMULATOR_FIRESTORE_URL="http://localhost:8080",
  REACT_APP_FIREBASE_EMULATOR_AUTH_URL="http://localhost:9099",
  REACT_APP_MAINTENANCE_MODE="false",
} = process.env;

export const STRIPE_CLIENT_API_KEY = REACT_APP_STRIPE_CLIENT_API_KEY;
export const STRIPE_CLIENT_SECRET = REACT_APP_STRIPE_CLIENT_SECRET;
export const FIREBASE_EMULATOR_DATABASE_HOST = REACT_APP_FIREBASE_EMULATOR_DATABASE_HOST;
export const FIREBASE_EMULATOR_DATABASE_PORT = REACT_APP_FIREBASE_EMULATOR_DATABASE_PORT;
export const FIREBASE_EMULATOR_FIRESTORE_URL = REACT_APP_FIREBASE_EMULATOR_FIRESTORE_URL;
export const FIREBASE_EMULATOR_AUTH_URL = REACT_APP_FIREBASE_EMULATOR_AUTH_URL;
export const MAINTENANCE_MODE = REACT_APP_MAINTENANCE_MODE;

import {
  GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider,
  setPersistence, browserLocalPersistence,
} from 'firebase/auth';

import { auth } from 'auth/app';

setPersistence(auth, browserLocalPersistence);  // cached between visits

const googleAuth = {
  provider: GoogleAuthProvider.PROVIDER_ID,
  scopes: [
    "https://www.googleapis.com/auth/userinfo.email",
    // "https://www.googleapis.com/auth/contacts.readonly",
  ],
  customParameters: {
    prompt: 'select_account',  // Force account selection
  },
};

const facebookAuth = {
  provider: FacebookAuthProvider.PROVIDER_ID,
  scopes: [
    "email",
    "user_birthday",
    // "user_friends",
  ],
  customParameters: {
    auth_type: 'reauthenticate',  // Force password re-entry
  },
};

const signInOptions = [
  googleAuth,
  facebookAuth,
  EmailAuthProvider.PROVIDER_ID,
  // GithubAuthProvider.PROVIDER_ID,
];

function signInFailure(error) {
  if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
    return Promise.resolve();
  }
  // The credential the user tried to sign in with.
  const cred = error.credential;

  // TODO: Copy data from anonymous user to permanent user and delete
  // anonymous user.

  // Finish sign-in after data is copied.
  return auth.signInWithCredential(cred);
}

const options = {
  signInOptions,
  tosUrl: '<your-tos-link>',
  signInFlow: 'popup',
  privacyPolicyUrl: () => window.location.assign('https://mythulu.com/privacy-policy/'),
  callbacks: {
    signInFailure,
  },
};

export default options;

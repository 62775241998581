import GlobalNavBar from 'components/global/GlobalNavBar';
import NavBar from 'components/global/NavBar';
import Footer from 'components/global/Footer';


export default function GlobalFrame({ maintenance=false, nav, children }) {
  return (
    <div className="text-dark-800 dark:text-light-100 h-screen flex flex-col">

      {!maintenance && <GlobalNavBar />}
      {!maintenance && <NavBar nav={nav} />}

      <div className="flex-grow bg-slate-100 dark:bg-slate-900">
        {children}
      </div>

      {!maintenance && <div className="flex-shrink">
        <Footer />
      </div>}

    </div>
  );
}

import React from 'react';

import Content from 'layouts/Content';
import Magazine from 'components/Magazine';


export default function Home({ splash: { productCode, issueCode } }) {
  return (
    <Content>
      <Magazine productCode={productCode} issueCode={issueCode} />
    </Content>
  );
}

import { useSearchParams } from 'react-router-dom';

import Content from 'layouts/Content';


export default function StripeExit() {
  const [ query ] = useSearchParams();
  // const session_id = query.get('session_id');
  const success = query.get('success');
  const canceled = query.get('canceled');

  return (
    <Content className="text-center">
      {success && (
        <p>Success!</p>
      )}
      {canceled && (
        <p>Transaction canceled.</p>
      )}
    </Content>
  );
}

import React from 'react';

import context from 'magazines/models';
import templates from 'magazines/templates';

const { "Single Feature": { toc } } = templates["genre-magazine/quarterly"];


export function useIssue(product, issue) {
  const models = useModels(product, issue);
  const [ loading, setLoading ] = React.useState(null);
  const [ data, setData ] = React.useState({ toc });

  React.useEffect(() => {
    console.debug('stream getIssue', { product, issue });
    setLoading(true);
    const unsubscribe = models.getIssue((payload) => {
      console.info('+ getIssue', { product, issue }, payload);
      setLoading(false);
      setData(payload);
    });
    return () => {
      console.debug('stop getIssue', { product, issue });
      return unsubscribe();
    };
  }, [product, issue, models]);

  return [ data, loading ];
}


export function useModels(product, issue) {
  return React.useMemo(() => context(product, issue), [product, issue])
}

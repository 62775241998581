import React from 'react';

import { useLocation, matchPath } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';

// import { useAuth } from 'auth/hooks';
import AdminBar from 'layouts/bars/AdminBar';
import PaymentBar from 'layouts/bars/PaymentBar';
import LocationBar from 'layouts/bars/LocationBar';


export default function NavBar({ nav/* , admin */ }) {
  // const { isAdmin } = useAuth();
  const { pathname } = useLocation();
  const links = nav.map(link => ({
    ...link,
    active: matchPath(link.to, pathname),
  }));
  // const adminLinks = admin.map(link => ({
  //   ...link,
  //   active: matchPath(link.to, pathname),
  // }));

  return (
    <Disclosure as="nav" className="sticky top-0 bg-gray-200 dark:bg-gray-700">
      {({ open }) => (
        <div className="max-w-7xl mx-auto px-2">

          <div className={`
            flex justify-between items-center h-16 gap-1 px-1
          `}>

            <div className="flex justify-start">
              <div className="flex space-x-4">
                {links.map(item => <NavLink item={item} key={item.name} />)}
                {/* {isAdmin && adminLinks.map(item => <NavLink item={item} key={item.name} />)} */}
              </div>
            </div>

            <LocationBar className="flex-1" />
            <AdminBar />
            <PaymentBar />
          </div>

        </div>
      )}
    </Disclosure>
  );
}


function NavLink({ item: { name, to, active } }) {
  return (
    <a
      href={to}
      className={[
        active
          ? 'text-white bg-gray-900'
          : "text-gray-600 dark:text-gray-300 hover:bg-gray-700 hover:text-white"
          ,
        'text-sm px-3 py-2 rounded-md font-medium flex-none',
      ].join(" ")}
      aria-current={to ? 'page' : undefined}
    >
      {name}
    </a>
  );
}

import React from 'react';

import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { ReactComponent as Logo } from 'assets/svg/logo.svg';
import { useAuth } from 'auth/hooks';
import MenuSignIn from 'auth/menus/MenuSignIn';
import MenuUser from 'auth/menus/MenuUser';


const links = [
  { name: 'Cards', to: 'https://mythulu.com/product/half-core-decks-a-pack/' },
  { name: 'E-Magazine', to: '/', active: true },
  { name: 'Store', to: 'https://mythulu.com/shop/' },
  { name: 'Contact Us', to: 'https://mythulu.com/contact-us/' },
];


export default function GlobalNavBar() {
  const { user } = useAuth();

  return (
    <Disclosure as="nav" className="bg-gray-100 dark:bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-24">

              <div className="inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className={`
                  inline-flex items-center justify-center p-2 rounded-md text-gray-400
                  hover:text-white hover:bg-gray-500 hover:bg-gray-700
                  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
                `}>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className={`
                flex-1 flex
                items-center sm:items-stretch
                justify-center sm:justify-start
              `}>

                <Logo className="pl-5 h-14 sm:h-12 lg:h-18" />

                <div className="hidden sm:block flex-1" />
                <div className="hidden sm:block sm:ml-6 space-x-4 self-center">
                  {links.map(item => <NavLink item={item} key={item.name} />)}
                </div>

              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {user
                  ? <MenuUser user={user} />
                  : <MenuSignIn />}
              </div>

            </div>
          </div>

          <CollapsedMenu links={links} className="md:hidden" />
        </>
      )}
    </Disclosure>
  );
}


function NavLink({ item: { name, to, active } }) {
  return (
    <a
      href={to}
      className={`
        ${active
          ? 'sm:inline-block bg-gray-900 text-white'
          : 'text-gray-600 dark:text-gray-300 hover:bg-gray-700 hover:text-white'}
        md:inline-block hidden font-nav px-3 py-2 rounded-md font-light text-sm sm:text-lg md:text-xl
      `}
      aria-current={to ? 'page' : undefined}
    >
      {name}
    </a>
  );
}


function CollapsedMenu({ links, className="" }) {
  return (
    <Disclosure.Panel className={className}>
      <div className="px-2 pt-2 pb-3 space-y-1">
        {links.map(({ name, to, active }) => (
          <Disclosure.Button
            key={name}
            as="Link"
            to={to}
            className={`
              ${active
                ? 'bg-gray-900 text-white'
                : 'text-gray-600 dark:text-gray-300 hover:bg-gray-700 hover:text-white'}
              px-3 py-2 rounded-md font-medium block text-base
            `}
            aria-current={to ? 'page' : undefined}
          >
            {name}
          </Disclosure.Button>
        ))}
      </div>
    </Disclosure.Panel>
  );
}

import { Menu } from '@headlessui/react';

import { auth } from 'auth/app';
import TransitionScaleFade from 'transitions/TransitionScaleFade';


export default function MenuUser({ user }) {
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className={`
          bg-gray-200 dark:bg-gray-900 flex text-sm rounded-full
          focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-offset-gray-800 focus:ring-amber-500
        `}>
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full"
            src={user.photoURL}
            crossOrigin="anonymous"
            alt=""
          />

        </Menu.Button>
      </div>
      <TransitionScaleFade>
        <Menu.Items className={`
          absolute origin-top-right right-0
          py-1 mt-2 w-48 z-10
          shadow-lg shadow-dark-500
          bg-white dark:bg-gray-900
          rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none
        `}>
          <Menu.Item>
            {({ active }) => (
              <a
                href="# "
                className={`
                  block px-4 py-2 text-sm
                  text-gray-700 dark:text-gray-300
                  ${active ? 'bg-gray-100 dark:bg-amber-400 dark:text-gray-900' : ''}
                `}
              >
                Your Profile
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                href="# "
                onClick={() => auth.signOut()}
                className={`
                  block px-4 py-2 text-sm
                  text-gray-700 dark:text-gray-300
                  ${active ? 'bg-gray-100 dark:bg-amber-400 dark:text-gray-900' : ''}
                `}
              >
                Sign out
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </TransitionScaleFade>
    </Menu>
  );
}

import React from 'react';
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "auth/app";


export function useAuth() {
  const [ user, setUser ] = React.useState(null);
  const [ isAdmin, setIsAdmin ] = React.useState(null);

  React.useEffect(() => onAuthStateChanged(auth, (user) => {
    setUser(user);
    if (user) {
      user.getIdTokenResult().then(
        (idTokenResult) => setIsAdmin(!!idTokenResult.claims.isAdmin)
      );
    }
  }), []);

  return {
    user,
    isAdmin,
    refresh: () => user.getIdToken(true),
  };
}

import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getAnalytics, isSupported as analyticsSupported } from 'firebase/analytics';

import {
  FIREBASE_EMULATOR_DATABASE_HOST,
  FIREBASE_EMULATOR_DATABASE_PORT,
  FIREBASE_EMULATOR_FIRESTORE_URL,
} from 'config';


const firebaseConfig = {
  apiKey: "AIzaSyBJV7r3xMxkq-DIkTpQGY7X4g_Th1sT58Q",
  authDomain: "mythulu-app.firebaseapp.com",
  databaseURL: "https://mythulu-app-default-rtdb.firebaseio.com",
  projectId: "mythulu-app",
  storageBucket: "mythulu-app.appspot.com",
  messagingSenderId: "37323338207",
  appId: "1:37323338207:web:01a24f61b1d44b6775546a",
  measurementId: "G-SSMPJX9RW5"
};
export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const database = getDatabase(app);

analyticsSupported()
  .then(enable => enable ? getAnalytics(app) : null)
  ;

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, FIREBASE_EMULATOR_FIRESTORE_URL);
  connectDatabaseEmulator(database, FIREBASE_EMULATOR_DATABASE_HOST, FIREBASE_EMULATOR_DATABASE_PORT);
}

import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_CLIENT_API_KEY, STRIPE_CLIENT_SECRET } from 'config';


const promise = loadStripe(STRIPE_CLIENT_API_KEY);

export default promise;

export const options = {
  clientSecret: STRIPE_CLIENT_SECRET,
};

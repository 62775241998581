import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

import { LocationBarPortal } from 'layouts/bars/LocationBar';


export default function LocationBar({ children }) {
  return (
    <LocationBarPortal>
      <div className="flex items-center">
        <ChevronDoubleRightIcon className="w-5 h-5 mx-3" />
        {children}
      </div>

    </LocationBarPortal>
  );
}

export default function Grid({ cols, gap=8, children, className='', ...props }) {
  return (
    <div className={
      `grid grid-cols-${cols} gap-${gap} ${className} `
      }
      {...props}
    >
      {children}
    </div>
  );
}

import React from 'react';
import { NewspaperIcon } from '@heroicons/react/24/outline';

import AdminBar from 'components/bars/AdminBar';
import LocationBar from 'components/bars/LocationBar';
import Card from 'components/cards/DefaultCard';
import Grid from 'layouts/Grid';
import PaidContent from 'layouts/PaidContent';
import { useIssue, useModels } from 'magazines/hooks';
import { useAuth } from 'auth/hooks';


const templates = [
  'Single Feature',
  'Other',
];


export default function Magazine({ productCode, issueCode }) {
  const { isAdmin } = useAuth();
  const [ data, loading ] = useIssue(productCode, issueCode);
  const api = useModels(productCode, issueCode);
  const { editIssueTemplate, editIssueTitle, addTocPanel } = api || {};
  const { toc } = data || {};
  const { title='New Issue', article_order, articles } = toc || {};

  const [ adminTitle, setAdminTitle ] = React.useState(null);

  return (true || !loading) && (
    <>
      <LocationBar>
        <div className="flex flex-col">
          <h1 className="text-xl font-serif">{toc?.title || 'Create Issue'}</h1>
          <h2>{productCode} • {issueCode}</h2>
        </div>
      </LocationBar>

      {isAdmin && (
        <>
          <AdminBar buttons={[
            toc && (
              { label: 'Add Panel', onClick: () => addTocPanel(toc, +new Date()) }
            ),
            (adminTitle && adminTitle !== title) && (
              { label: 'Save Title', onClick: () => editIssueTitle(adminTitle) }
            ),
          ]} />
          <div className="flex items-center justify-center w-full gap-10">
            {templates.map((value, i) => (
              <button
                key={i}
                onClick={() => editIssueTemplate(toc, value)}
                className={`
                  flex items-center justify-center w-48
                  rounded-md border-3 border-gray-500
                  text-md
                `}
              >
                <NewspaperIcon className="w-6 h-6 mr-1" />
                {value}
              </button>
            ))}
          </div>
        </>
      )}

      <PaidContent stripe_lookup_key={productCode}>
        <header className={`
          grid place-content-stretch text-center
          w-full h-128 mb-8
          bg-pink-100 dark:bg-dark-900
        `}>
          <h1
            contentEditable={isAdmin}
            suppressContentEditableWarning={isAdmin}
            onInput={e => setAdminTitle(e.target.innerText)}
            className="flex items-center justify-center text-6xl bg-slate-800 font-serif shadow-2xl"
          >
            {title}
          </h1>
        </header>
        <Grid cols={4} className="font-serif">
          {toc?.panel_order.map(id => {

            const {
              featured, image, title, byline, summary, rows, cols,
            } = toc?.panels?.[id] || {};
            return (
              <Card
                key={id}

                product={productCode}
                issue={issueCode}

                panelId={id}
                data={data}

                featured={featured}
                image={image}
                title={title}
                byline={byline}
                summary={summary}
                rows={rows}
                cols={cols}
              />
            );

          })}
        </Grid>

        {toc && (
          <>
            <h1 className="text-6xl font-serif">{toc?.title}</h1>
            <h2 className="text-4xl font-serif">Table of Contents</h2>
            <div className="flex flex-col gap-4">
              {article_order?.map(id => (
                <div key={id} className="flex flex-row">
                  <div className="w-11/12">{articles[id].title}</div>
                  <div className="w-1/12">{articles[id].pageNumber || '?'}</div>
                </div>
              ))}
            </div>
          </>
        )}

      </PaidContent>

    </>
  );
}

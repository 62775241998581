import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GlobalFrame from 'layouts/global/GlobalFrame';
import Home from 'pages/Home';
import Login from 'pages/Login';
import StripeExit from 'pages/StripeExit';
import NotFound from 'pages/NotFound';


export default function App() {
  return (
    <BrowserRouter>
      <GlobalFrame
        nav={[
          { name: 'Home', to: '/' },
          { name: 'Submissions', to: 'https://mythulu.submittable.com/submit' },
        ]}
      >
        <Routes>
          <Route exact path="/" element={(
            <Home splash={{
              productCode: 'genre-magazine/quarterly',
              issueCode: '2020d',
            }} />
          )} />
          <Route path="/stripe-exit" element={<StripeExit />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </GlobalFrame>
    </BrowserRouter>
  );
}

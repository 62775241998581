import { getDatabase, ref, set, update, onValue } from 'firebase/database';

import templates from 'magazines/templates';

const db = getDatabase();

/**
 * Get db methods for target magazine issue
 * @param {string} product - The product code, i.e. 'genre-magazine/quarterly', 'genre-magazine/free-samples'
 * @param {string} issue - The issue code, i.e. '2020d'. This is a shortcode unique to a release.
 */
export default function getContext(product, issue) {
  return {
    // initIssue: (title, templateName) => initIssue(product, issue, title, templateName),
    getIssue: (callback) => getIssue(product, issue, callback),
    editIssueTemplate: (toc, templateName) => editIssueTemplate(product, issue, toc, templateName),
    editIssueTitle: (toc, templateName) => editIssueTitle(product, issue, toc, templateName),
    editTocPanelOrder: (panel_order) => editTocPanelOrder(product, issue, panel_order),
    editTocPanelSettings: (panelId, settings) => editTocPanelSettings(product, issue, panelId, settings),
    addTocPanel: (toc, panelId) => addTocPanel(product, issue, toc, panelId),
    deleteTocPanel: (toc, panelId) => deleteTocPanel(product, issue, toc, panelId),
  };
}


// function initIssue(product, issue, title, templateName) {
//   const path = `magazines/${product}/${issue}`;
//   const templateFactory = templates[product][templateName];
//   return set(ref(db, path), templateFactory({ title }));
// }

function getIssue(product, issue, callback) {
  const issueDoc = ref(db, `magazines/${product}/${issue}`);
  return onValue(issueDoc, snapshot => callback(snapshot.val(), snapshot));
}

function editIssueTemplate(product, issue, toc, templateName) {
  const path = `magazines/${product}/${issue}`;
  const template = JSON.parse(JSON.stringify(templates[product][templateName]));
  if (toc) {
    Object.entries(toc.panels).forEach(([panelId, panel]) => {
      toc.panels[panelId] = {
        ...panel,
        ...(template.toc.panel[panelId] || {}),
      };
    });
    console.log('Update in place', { toc });
    return update(ref(db, path), { toc });
  } else {
    console.log('Initialize', template);
    return update(ref(db, path), template);
  }
}

function editIssueTitle(product, issue, title) {
  const issueDoc = ref(db, `magazines/${product}/${issue}/toc`);
  return update(issueDoc, { title });
}

async function addTocPanel(product, issue, toc, panelId) {
  const issuePath = `magazines/${product}/${issue}`;
  return Promise.all([
    update(
      ref(db, `${issuePath}/toc/panel_order`),
      (toc?.panel_order || []).concat([panelId]),
    ),
    set(
      ref(db, `${issuePath}/toc/panels/${panelId}`),
      {
        featured: false,
        article: null,
        title: null,
        byline: null,
        summary: null,
        rows: 1,
        cols: 1,
      },
    ),
  ]);
}

async function deleteTocPanel(product, issue, toc, panelId) {
  const path = `magazines/${product}/${issue}/toc/`;
  await update(ref(db, path), {
    [`panels/${panelId}`]: null,
    panel_order: (toc?.panel_order || []).filter(id => id !== panelId),
  });
}

async function editTocPanelOrder(product, issue, panel_order) {
  const path = `magazines/${product}/${issue}/toc/panel_order`;
  await update(ref(db, path), panel_order);
}

async function editTocPanelSettings(product, issue, panelId, {
  featured, rows, cols, title, byline, summary,
}) {
  console.log('editTocPanelSettings', product, issue, panelId);
  const path = `magazines/${product}/${issue}/toc/panels/${panelId}`;
  const updates = Object.fromEntries([
    [`featured`, featured],
    [`rows`, rows],
    [`cols`, cols],
    [`title`, title],
    [`byline`, byline],
    [`summary`, summary],
  ].filter(([_, value]) => value !== undefined));
  console.log(updates);
  await update(ref(db, path), updates);
};

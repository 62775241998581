import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GlobalFrame from 'layouts/global/GlobalFrame';


export default function MaintenanceMode() {
  return (
    <BrowserRouter>
      <GlobalFrame maintenance={true}>
        <Routes>
          <Route path="/*" element={<MassNotice />} />
        </Routes>
      </GlobalFrame>
    </BrowserRouter>
  );
}


function MassNotice() {
  return (
    <div className="flex flex-col justify-content h-full w-full bg-slate-800">
      <div className="w-full xl:w-max-256 h-full flex flex-col justify-center mx-auto my-8">
        <h1 className="text-5xl md:text-7xl font-serif mb-10 text-slate-400 text-center">
          <span className="text-shadow text-shadow-md shadow-black">
            Maintenance
          </span>
        </h1>
        <p className="text-lg md:text-3xl text-slate-300 text-center">
          Fabulous things are happening just behind the curtain.
        </p>
        <div className="h-80"></div>
      </div>
    </div>
  );
}

import React from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { PaymentBarPortal } from 'layouts/bars/PaymentBar';
import stripe from 'integrations/stripe';


export default function PaymentBar({ lookup_key, hasPaid, className, ...props }) {
  return (
    <PaymentBarPortal>
      <Elements stripe={stripe}>
        <form
          action="http://localhost:4243/genre-magazine/subscribe"
          method="post"
          className={`h-full p-1 cursor-pointer ${className}`}
          {...props}
        >
          <button
            type="submit"
            className="h-full px-3 flex items-center bg-gray-800 hover:bg-gray-900 rounded"
          >
            <div className="grid grid-rows-2 text-right mr-4">
              <span className="text-gray-200">4 issues per year</span>
              {hasPaid
                ? <span className="text-gray-400">until {}</span>
                : <span className="text-gray-400">$20 annually</span>
              }
            </div>
            {hasPaid ? (
              <span className={`
                flex-shrink bg-emerald-500 text-dark dark:text-white p-2 rounded
              `}>
                Subscribed
              </span>
            ) : (
              <span className={`
                flex-shrink bg-blue-500 text-dark dark:text-white p-2 rounded
              `}>
                Subscribe
              </span>
            )}
          </button>
        </form>
      </Elements>
    </PaymentBarPortal>
  );
};

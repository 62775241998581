import React from 'react';

// import { useAuth } from 'auth/hooks';
import PaymentBar from 'components/bars/PaymentBar';
import Content from 'layouts/Content';


export default function PaidContent({ stripe_lookup_key, ...props }) {
  // const { isAdmin } = useAuth();
  const [ hasPaid, setHasPaid ] = React.useState(null);

  React.useEffect(() => {
    // TODO: Get subscription status from server via `stripe_lookup_key`
    const hasActiveSubscription = false;
    setHasPaid(hasActiveSubscription);
  }, []);

  return (
    <>
      <PaymentBar lookup_key={stripe_lookup_key} hasPaid={hasPaid} />

      <Content {...props} />
    </>
  );
}

import React from 'react';

import { Dialog } from '@headlessui/react';

import { useModels } from 'magazines/hooks';


const overhangSize = 300;
const rowClasses = `
  grid grid-cols-2
  flex items-start
  py-1
  bg-slate-200 dark:bg-slate-800
`;
const labelClasses = `
  text-right
  text-gray-800 dark:text-gray-100
`;
const inputClasses = `
  flex flex-row items-center justify-start
  rounded-sm py-0 px-2 ml-2
  bg-slate-100 dark:bg-slate-900
  text-dark dark:text-white
`;

export default function AdminCard({
  product, issue, panelId, onChange, isOpen, close, position,
  defaults,
  data: { toc },
}) {
  const { article_order=[] } = toc || {};
  const { editTocPanelSettings, deleteTocPanel } = useModels(product, issue);

  const {
    title, byline, summary/* , image */, cols, rows, featured, article,
  } = defaults;
  // const [ clean, setClean ] = React.useState(true);
  const [ data, setData ] = React.useState(null);

  const reset = React.useCallback(() => {
    onChange(null);
  }, [onChange]);

  const setField = React.useCallback((key, value) => {
    onChange(key, value);
    setData({ ...data, [key]: value });
  }, [onChange, setData, data]);

  function adminDelete() {
    deleteTocPanel(toc, panelId);
    close();
  }

  function adminCancel() {
    setField(null);
    close();
  }

  const adminSave = React.useCallback(async () => {
    await editTocPanelSettings(product, issue, panelId, data);
    reset();
  }, [product, issue, panelId, data, reset, editTocPanelSettings]);

  const positionStyle = React.useMemo(function getPosition() {
    const { top, left, width, height } = position;
    return {
      top: `${top - overhangSize}px`,
      left: `${left - 20}px`,
      width: `${width + 40}px`,
      height: `${height + overhangSize + 20}px`,
    };
  }, [position]);

  return (
    <Dialog open={isOpen} onClose={close} className="relative z-49">
      <div className={`
        fixed h-screen w-screen inset-0 flex items-center justify-center bg-slate-500/50
      `}>
        <Dialog.Panel className="absolute bg-slate-500 dark:bg-slate-500/50" style={positionStyle}>

          <div className="grid grid-cols-auto">

            <div className={rowClasses}>
              <label htmlFor="admin-featured" className={labelClasses}>
                Featured:
              </label>
              <input id="admin-featured" className={`${inputClasses} dark:text-amber-600`}
                type="checkbox"
                autoComplete="off"
                defaultChecked={Boolean(featured)}
                onChange={event => setField('featured', event.target.checked)}
              />
            </div>

            <div className={rowClasses}>
              <label htmlFor="admin-size" className={labelClasses}>
                Size:
              </label>
              <select id="admin-size" className={`${inputClasses} w-16`}
                onChange={event => {
                  const [rows, cols] = event.target.value.split('x').map(Number);
                  setField('rows', rows);
                  setField('cols', cols);
                }}
                defaultValue={`${rows}x${cols}`}
              >
                {[
                  '1x1', '1x2', '1x3', '1x4',
                  '2x1', '2x2',
                  '3x1',        '3x3',
                ].map((v, i) => <option key={i} value={v}>{v}</option>)}
              </select>
            </div>

            <div className={rowClasses}>
              <label htmlFor="admin-article" className={labelClasses}>
                Article:
              </label>
              <select id="admin-article" className={inputClasses}
                onChange={event => setField('article', event.target.value)}
                defaultValue={article}
              >
                <option value={null}>(None)</option>
                {article_order.map((v, i) => (
                  <option key={i} value={v}>{v}</option>
                ))}
              </select>
            </div>

            <div className={rowClasses}>
              <label htmlFor="admin-title" className={labelClasses}>
                Force title:
              </label>
              <input id="admin-title" className={inputClasses}
                type="text"
                defaultValue={title}
                onChange={event => setField('title', event.target.value)}
              />
            </div>

            <div className={rowClasses}>
              <label htmlFor="admin-byline" className={labelClasses}>
                Force byline:
              </label>
              <input id="admin-byline" className={inputClasses}
                type="text"
                defaultValue={byline}
                onChange={event => setField('byline', event.target.value)}
              />
            </div>

            <div className={rowClasses}>
              <label htmlFor="admin-summary" className={labelClasses}>
                Override summary:
              </label>
              <textarea id="admin-summary" className={inputClasses}
                defaultValue={summary}
                onChange={event => setField('summary', event.target.value)}
              />
            </div>

          </div>

          <div className={`
            flex flex-row gap-1 justify-end
            p-1 text-md
            bg-slate-100 dark:bg-slate-900
          `}>
            <button className="px-1 rounded-sm dark:bg-red-600" onClick={adminDelete}>Delete</button>
            <div className="flex-1" />
            <button className="px-1 rounded-sm dark:bg-gray-400" onClick={adminCancel}>Cancel</button>
            <button className="px-1 rounded-sm bg-amber-600" onClick={adminSave}>Save</button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

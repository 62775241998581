const templates = {
  "genre-magazine/quarterly": {
    "Single Feature": {
      toc: {
        title: 'Sample Magazine',

        panel_order: [
          'editor-featured-1', 'a', 'b', 'c', 'd',
        ],
        panels: {
          'editor-featured-1': {
            featured: true,
            rows: 1,
            cols: 3,
          },
          'a': {
            feature: false,
            rows: 1,
            cols: 1,
          },
          'b': {
            feature: false,
            rows: 1,
            cols: 1,
          },
          'c': {
            feature: false,
            rows: 1,
            cols: 1,
          },
          'd': {
            feature: false,
            rows: 1,
            cols: 1,
          },
        },

        article_order: ['x', 'y', 'z'],
        articles: {
          x: {
            title: 'Sample X',
            pageNumber: 1,
          },
          y: {
            title: 'Sample Y',
            pageNumber: 2,
          },
          z: {
            title: 'Sample Z',
            pageNumber: 3,
          },
        },
      },
    },
    "Other": {
      toc: {
        title: 'Other',

        panel_order: [
          'editor-featured-1',
        ],
        panels: {
          'editor-featured-1': {
            featured: true,
            rows: 1,
            cols: 2,
          },
        },

        article_order: [],
        articles: {},
      },
    },
  },
};
export default templates;

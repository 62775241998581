import React from 'react';
import { AdjustmentsHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline'

import { useAuth } from 'auth/hooks';
import DefaultAdminCard from 'components/cards/DefaultCardAdmin';


export default function DefaultCard({
  product,  // genre-magazine/quarterly
  issue,    // 2020d (year + a/b/c/d for quarter)
  panelId,  // Anything, but is a unique block in the issue's ToC

  article=null,
  featured=false,
  image="https://via.placeholder.com/200x300/111.png",
  title="TBD",
  byline="by TBD",
  summary="Summary TBD",

  cols=1,
  rows=1,

  data,
}) {
  const { isAdmin } = useAuth();
  const ref = React.useRef();
  const [ adminOpen, setAdminOpen ] = React.useState(false);
  const [ adminRect, setRect ] = React.useState(null);
  const [ adminPreview, setAdminPreview ] = React.useState(null);

  const vertical = rows >= cols;
  const placeholder = `https://via.placeholder.com/${500}x${500}/111.png`; /* 111 is hex color */

  const getRect = React.useCallback(el => setRect(el.getBoundingClientRect()), []);
  React.useLayoutEffect(
    () => ref.current && getRect(ref.current),
    [ref, adminPreview, getRect]);
  React.useEffect(
    () => ref.current && getRect(ref.current),
    [ref, adminPreview, getRect]);

  // Effective values, considering preview overrides
  const [
    _featured, _article, _title, _byline, _summary, _rows, _cols,
  ] = React.useMemo(() => {
    const _toc = data?.toc;
    const _article = _toc?.articles?.[adminPreview?.article];
    return [
      adminPreview?.featured !== undefined ? adminPreview.featured : featured,
      adminPreview?.article || article,
      adminPreview?.title || _article?.title || title,
      adminPreview?.byline || _article?.byline || byline,
      adminPreview?.summary || _article?.summary || summary,
      adminPreview?.rows || _toc?.rows || rows,
      adminPreview?.cols || _toc?.cols || cols,
    ];
  }, [adminPreview, featured, article, title, byline, summary, rows, cols, data?.toc]);

  return (
    <>
      {panelId && adminOpen && isAdmin && adminRect && (
        <DefaultAdminCard
          product={product}
          issue={issue}
          panelId={panelId}

          defaults={{
            title: _article?.title ? null : 'xyz',
            byline: _article?.byline ? null : 'xyz',
            summary: _article?.summary ? null : 'xyz',
            image: _article?.image ? null : 'xyz',
            cols,
            rows,
            featured
          }}
          data={data}

          position={adminRect}
          onChange={(key, value) => {
            if (key === null) {
              setAdminPreview(null);
            } else {
              setAdminPreview(data => ({ ...data, [key]: value }));
            }
          }}
          isOpen={adminOpen}
          close={() => setAdminOpen(false)}
        />
      )}

      <div
        ref={ref}
        className={`
          flex rounded-lg border overflow-hidden
          row-span-${_rows} col-span-${_cols}
          ${vertical ? 'flex-col' : 'flex-row'}
          shadow-md shadow-gray-200 dark:shadow-dark-800
          ${_featured
              ? 'border-3 border-amber-300 dark:border-amber-700'
              : 'border-slate-300 dark:border-gray-700'
          }
          flex bg-slate-800
          ${adminOpen ? 'z-50' : ''}
        `}
      >
        <img
          className={`
            object-cover
            border-l border-r
            border-slate-300 dark:border-slate-700
          `}
          src={image || placeholder}
          alt="Placeholder"
        />

        <figcaption className={`
          p-3 ${vertical ? 'border-t' : 'border-l'}
          flex-1 flex flex-col justify-end
          bg-slate-100 dark:bg-slate-800
          border-slate-400 dark:border-slate-600
        `}>
          {_title && <h1 className={`${(_rows * _cols > 1) ? 'text-5xl' : 'text-3xl'} font-brand font-bold pt-4 px-2 dark:text-light-900`}>{_title}</h1>}
          {_byline && <p className="text-sm text-sm font-medium px-2 dark:text-light-900">{_byline}</p>}
          {_summary && <p className="text-md text-gray-500 p-2">{_summary}</p>}
          {isAdmin && (
            <aside className="flex justify-end bg-slate-900">
              <button
                className="absolute mt-[-1em] text-amber-500"
                onClick={() => setAdminOpen(!adminOpen)}
              >
                {adminOpen ? <XMarkIcon className="w-5 h-5" /> : <AdjustmentsHorizontalIcon className="w-5 h-5" />}
              </button>
            </aside>
          )}
        </figcaption>
      </div>
    </>
  );
}
